//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useState } from "react";

// Material ui Imports
import { Grid, ImageList, ImageListItem, Skeleton, SxProps, Tooltip, Typography, useTheme } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

// Custom components imports (and hooks and helpers)
import {
    VTButton,
    VTTextField,
    VTHTMLHotspot,
    VTSidePanelHotspotWrapper,
    VTHTMLHotspotRightWrongAnswerToggle,
} from "@virtus-tech-repository/virtus-tech-repository";
import { EDraggableOptions, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { Preview, RadioButtonCheckedRounded } from "@mui/icons-material";

// Media imports
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ReactComponent as Movement } from "../components/movement.svg";
import SelectAllRoundedIcon from "@mui/icons-material/SelectAllRounded";
import { Draggable, Droppable, Position } from "react-beautiful-dnd";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";
import {
    EDraggableHotspotId,
    EDroppableContainerId,
} from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
import { setCurrentDoubleClick, setCurrentHotspot, setCurrentHotspotTransfer } from "../../store/slices/current.slice";
import {
    useDeleteGroupHotspotMutation,
    useDeleteHotspotMutation,
    useGetGroupHotspotsQuery,
    useGetHotspotsQuery,
} from "../../services/hotspot.service";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { v4 as uuid } from "uuid";
import { Params, useParams } from "react-router-dom";
import { AddCircleOutlineRounded, DeleteRounded } from "@mui/icons-material";
import { SettingsRounded } from "@mui/icons-material";

import NoHotspotTemplates from "../../assets/images/NoScenarios.svg";
import { IconName, IconWrapper } from "../components/IconWrapper";
import { checkIconExistsInIconWrapper } from "../../utils/checkIconExists";
// const [searchValue, setSearchValue] = useState<string>("");

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sideDrawWidth: number;
    sx?: SxProps;
    handleDragStart?: (mediaType: EDraggableOptions, src: string, preview?: string) => void;
    handleDragStop?: () => void;
    videoCurrentState: "Paused" | "Playing" | "Question" | undefined;
}

export interface EDragOptions {}

// function getStyle(style: any, snapshot: any, moveToNew: Position) {
//     if (!snapshot.isDropAnimating) {
//         return style;
//     }
//     const { moveTo, curve, duration } = snapshot.dropAnimation;
//     // move to the right spot
//     const translate = `translate(${moveToNew.x}px, ${moveToNew.y}px)`;
//     // add a bit of turn for fun
//     const rotate = "rotate(0.5turn)";

//     // patching the existing style
//     return {
//         ...style,
//         transform: `${translate} ${rotate}`,
//         // slowing down the drop because we can
//         transition: `all ${curve} ${duration + 1}s`,
//     };
// }

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTHotspotSidePanel({
    sideDrawWidth,
    handleDragStart,
    handleDragStop,
    sx,
    videoCurrentState,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useAppDispatch();
    const { sceneId }: Readonly<Params<string>> = useParams();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { currentScene, currentlyDragging, currentScenario, currentQuestion, currentSidePanel } = useAppSelector(
        (state) => state.currentReducer,
    );
    const { organisation } = useAppSelector((state) => state.userReducer);

    const { id: userId } = useAppSelector((state) => state.userReducer);
    const [deleteQuestionHotspot, isLoading] = useDeleteGroupHotspotMutation();
    const [deleteHotspot] = useDeleteHotspotMutation();

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const { data: hotspotTemplates } = useGetGroupHotspotsQuery(userId);
    const { data: questionHotspots } = useGetGroupHotspotsQuery(currentQuestion ? currentQuestion.id : "");
    const { data: hotspots } = useGetHotspotsQuery(currentScene.id);
    const [templateLoading, setTemplateLoading] = useState<string>("");
    const [currentMouseOver, setCurrentMouseOver] = useState<string>("");

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    function handleButtonClick(buttonIndex: number) {
        setActiveButton(buttonIndex);
    }

    function localHandleDrag(
        dragged: boolean,
        mediaType?: EDraggableOptions,
        src?: string,
        preview?: string | undefined,
    ) {
        console.log("BDGG: dragged", dragged, mediaType, src);
        if (dragged && handleDragStart && mediaType && src) {
            handleDragStart(mediaType, src, preview);
        } else if (!dragged && handleDragStop) {
            handleDragStop();
        }
    }

    function handleDoubleClick(mediaType: EDraggableOptions, src: string) {
        dispatch(setCurrentDoubleClick({ src: src, mediaType: mediaType }));
    }

    const theme = useTheme();

    return (
        <Grid container xs={12} justifyContent={"center"} sx={{ height: "100%", ...sx }}>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Images search */}
            <Grid item xs={11}>
                <VTTextField
                    placeholder={"Search Hotspots"}
                    sx={{
                        marginTop: "20px",
                    }}
                    value={searchValue}
                    onChange={(textInput: string) => setSearchValue(textInput)}
                    type={"text"}
                    endIcon={<SearchRoundedIcon />}
                />
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Filters */}
            <Grid
                item
                container
                xs={11}
                sx={{
                    marginTop: "15px",
                    display: "flex",
                    overflowX: "auto",
                }}
            >
                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(0)}
                        sx={{
                            borderBottom: activeButton === 0 ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                            width: "100%",
                        }}
                        type="underline"
                    >
                        <Typography fontSize={"1em"}>
                            All <br /> Hotspots
                        </Typography>
                    </VTButton>
                </Grid>

                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(1)}
                        sx={{
                            borderBottom: activeButton === 1 ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                            width: "100%",
                        }}
                        type="underline"
                    >
                        <Typography fontSize={"1em"}>Scene Hotspots</Typography>
                    </VTButton>
                </Grid>

                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(2)}
                        sx={{
                            borderBottom: activeButton === 2 ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                            width: "100%",
                        }}
                        type="underline"
                    >
                        <Typography fontSize={"1em"}>Saved Hotspots</Typography>
                    </VTButton>
                </Grid>
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Image list */}

            {activeButton === 0 && (
                <Droppable droppableId={EDroppableContainerId.HOTSPOTS} type="DROPPABLE_MEDIA">
                    {(provided, snapshot) => (
                        <Grid
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            item
                            container
                            xs={12}
                            columnSpacing={1}
                            sx={{
                                marginTop: "5px",
                                overflowY: "scroll",
                                width: "100%",
                                padding: "20px",
                                height: "calc(100vh - 230px)",
                            }}
                            alignContent={"flex-start"}
                        >
                            {"information hotspot".includes(searchValue.toLowerCase()) && (
                                <Draggable
                                    key={0}
                                    draggableId={EDraggableHotspotId.INFO_HOTSPOT}
                                    index={0}
                                    isDragDisabled={false}
                                >
                                    {(provided, snapshot) => (
                                        <Grid
                                            item
                                            xs={6}
                                            container
                                            sx={{ marginBottom: "8px" }}
                                            onMouseEnter={() => setCurrentMouseOver(EDraggableHotspotId.INFO_HOTSPOT)}
                                        >
                                            <VTSidePanelHotspotWrapper
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                onDoubleClick={() =>
                                                    handleDoubleClick(
                                                        EDraggableOptions.INFORMATION,
                                                        `${process.env.PUBLIC_URL}/icons/info.svg`,
                                                    )
                                                }
                                                sx={{
                                                    width: "100%",
                                                    height: "180px",
                                                    transform:
                                                        (currentMouseOver !== EDraggableHotspotId.INFO_HOTSPOT &&
                                                            !snapshot.isDragging) ||
                                                        (currentlyDragging && !snapshot.isDragging)
                                                            ? "none !important"
                                                            : "auto",
                                                    ...(snapshot.isDragging && {
                                                        border: `4px dashed ${theme.palette.primary.light}`,
                                                        borderRadius: "16px",
                                                    }),
                                                }}
                                                title={"information hotspot"}
                                                nameCutOffPoint={currentSidePanel.currentWidth > 300 ? 19 : 9}
                                            >
                                                <VTHTMLHotspot
                                                    icon={
                                                        <IconWrapper
                                                            iconName={"Info"}
                                                            sx={{
                                                                height: "80px",
                                                                width: "80px",
                                                            }}
                                                        />
                                                    }
                                                    onClose={undefined}
                                                    scale2d={0.8}
                                                    title={"information"}
                                                    sx={{
                                                        marginTop: "-6px",
                                                        "> div > div": {
                                                            cursor: "inherit",
                                                            background: "rgba(30,30,30,0.5)",
                                                        },
                                                    }}
                                                />
                                            </VTSidePanelHotspotWrapper>

                                            {snapshot.isDragging && (
                                                <div
                                                    style={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        width: "100%",
                                                        height: "180px",
                                                        borderRadius: "15px",
                                                        transform: "none !important",
                                                    }}
                                                ></div>
                                            )}
                                        </Grid>
                                    )}
                                </Draggable>
                            )}

                            {"movement hotspot".includes(searchValue.toLowerCase()) && (
                                // <Tooltip title={"Movement hotspot"}>
                                <Draggable
                                    key={1}
                                    draggableId={EDraggableHotspotId.MOVEMENT_HOTSPOT}
                                    index={1}
                                    isDragDisabled={false}
                                >
                                    {(provided, snapshot) => (
                                        <Grid item xs={6} container sx={{ marginBottom: "8px" }}>
                                            <VTSidePanelHotspotWrapper
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                onDoubleClick={() =>
                                                    handleDoubleClick(
                                                        EDraggableOptions.MOVEMENT,
                                                        `${process.env.PUBLIC_URL}/icons/info.svg`,
                                                    )
                                                }
                                                sx={{
                                                    // ...provided.draggableProps.style,

                                                    width: "100%",
                                                    height: "180px",
                                                    transform:
                                                        (currentMouseOver !== EDraggableHotspotId.MOVEMENT_HOTSPOT &&
                                                            !snapshot.isDragging) ||
                                                        (currentlyDragging && !snapshot.isDragging)
                                                            ? "none !important"
                                                            : "auto",
                                                    ...(snapshot.isDragging && {
                                                        border: `4px dashed ${theme.palette.primary.light}`,
                                                        borderRadius: "16px",
                                                    }),
                                                    ...(snapshot.isDragging && {
                                                        border: `4px dashed ${theme.palette.primary.light}`,
                                                        borderRadius: "16px",
                                                    }),
                                                    // ...(snapshot.isDropAnimating && {
                                                    //     transform: `translate(200px, 200px)`,
                                                    // }),
                                                }}
                                                title={"movement hotspot"}
                                                nameCutOffPoint={currentSidePanel.currentWidth > 300 ? 18 : 8}
                                            >
                                                <VTHTMLHotspot
                                                    icon={
                                                        <IconWrapper
                                                            iconName={"Movement"}
                                                            sx={{
                                                                height: "80px",
                                                                width: "80px",
                                                            }}
                                                        />
                                                    }
                                                    onClose={undefined}
                                                    scale2d={0.8}
                                                    sx={{
                                                        marginTop: "-6px",
                                                        "> div > div": {
                                                            background: "rgba(30,30,30,0.5)",
                                                            cursor: "inherit",
                                                        },
                                                    }}
                                                />
                                            </VTSidePanelHotspotWrapper>

                                            {snapshot.isDragging && (
                                                <div
                                                    style={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        width: "100%",
                                                        height: "180px",
                                                        borderRadius: "15px",
                                                        transform: "none !important",
                                                    }}
                                                ></div>
                                            )}
                                        </Grid>
                                    )}
                                </Draggable>
                            )}

                            {"invisible hotspot".includes(searchValue.toLowerCase()) && (
                                <Draggable
                                    key={2}
                                    draggableId={EDraggableHotspotId.INVISIBLE_HOTSPOT}
                                    index={2}
                                    isDragDisabled={false}
                                >
                                    {(provided, snapshot) => (
                                        // <Tooltip title={"Invisible hotspot"}>
                                        <Grid item xs={6} container sx={{ marginBottom: "8px" }}>
                                            <VTSidePanelHotspotWrapper
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                onDoubleClick={() =>
                                                    handleDoubleClick(EDraggableOptions.ANSWER, `invisible`)
                                                }
                                                sx={{
                                                    width: "100%",
                                                    height: "180px",
                                                    transform:
                                                        (currentMouseOver !== EDraggableHotspotId.INVISIBLE_HOTSPOT &&
                                                            !snapshot.isDragging) ||
                                                        (currentlyDragging && !snapshot.isDragging)
                                                            ? "none !important"
                                                            : "auto",
                                                    ...(snapshot.isDragging && {
                                                        border: `4px dashed ${theme.palette.primary.light}`,
                                                        borderRadius: "16px",
                                                    }),
                                                    ...(snapshot.isDragging && {
                                                        border: `4px dashed ${theme.palette.primary.light}`,
                                                        borderRadius: "16px",
                                                    }),
                                                }}
                                                title={"invisible hotspot"}
                                                nameCutOffPoint={currentSidePanel.currentWidth > 300 ? 19 : 9}
                                            >
                                                <VTHTMLHotspot
                                                    // icon="movement"
                                                    onClose={undefined}
                                                    title=" "
                                                    // scale2d={0.8}
                                                    sx={{
                                                        // maxWidth: "none",
                                                        // width: "2500px",
                                                        marginTop: "-8px",
                                                        "> div > div": {
                                                            boxShadow: "0 0 25px #fff",
                                                            cursor: "inherit",
                                                        },
                                                    }}
                                                />
                                            </VTSidePanelHotspotWrapper>

                                            {snapshot.isDragging && (
                                                <div
                                                    style={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        width: "100%",
                                                        height: "180px",
                                                        borderRadius: "15px",
                                                        transform: "none !important",
                                                    }}
                                                ></div>
                                            )}
                                        </Grid>
                                    )}
                                </Draggable>
                                // </Tooltip>
                            )}

                            {"blank answer hotspot".includes(searchValue.toLowerCase()) && (
                                <Draggable
                                    key={3}
                                    draggableId={EDraggableHotspotId.BLANK_ANSWER_HOTSPOT}
                                    index={3}
                                    isDragDisabled={false}
                                >
                                    {(provided, snapshot) => (
                                        // <Tooltip title={"Answer hotspot"}>
                                        <Grid item xs={6} container sx={{ marginBottom: "8px" }}>
                                            <VTSidePanelHotspotWrapper
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                onDoubleClick={() =>
                                                    handleDoubleClick(EDraggableOptions.ANSWER, `blank`)
                                                }
                                                sx={{
                                                    width: "100%",
                                                    height: "180px",
                                                    transform:
                                                        (currentMouseOver !==
                                                            EDraggableHotspotId.BLANK_ANSWER_HOTSPOT &&
                                                            !snapshot.isDragging) ||
                                                        (currentlyDragging && !snapshot.isDragging)
                                                            ? "none !important"
                                                            : "auto",
                                                    ...(snapshot.isDragging && {
                                                        border: `4px dashed ${theme.palette.primary.light}`,
                                                        borderRadius: "16px",
                                                    }),
                                                }}
                                                title={"blank answer hotspot"}
                                                nameCutOffPoint={currentSidePanel.currentWidth > 300 ? 18 : 8}
                                            >
                                                <VTHTMLHotspot
                                                    // icon="movement"
                                                    onClose={undefined}
                                                    scale2d={0.8}
                                                    title="answer"
                                                    sx={{
                                                        // maxWidth: "none",
                                                        // width: "2500px",
                                                        marginTop: "-6px",
                                                        "> div > div": {
                                                            background: "rgba(30,30,30,0.5)",
                                                            cursor: "inherit",
                                                        },
                                                    }}
                                                />
                                            </VTSidePanelHotspotWrapper>

                                            {snapshot.isDragging && (
                                                <div
                                                    style={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        width: "100%",
                                                        height: "180px",
                                                        borderRadius: "15px",
                                                        transform: "none !important",
                                                    }}
                                                ></div>
                                            )}
                                        </Grid>
                                    )}
                                </Draggable>
                                // </Tooltip>
                            )}

                            {"correct answer hotspot".includes(searchValue.toLowerCase()) && (
                                <Draggable
                                    key={4}
                                    draggableId={EDraggableHotspotId.CORRECT_ANSWER_HOTSPOT}
                                    index={4}
                                    isDragDisabled={false}
                                >
                                    {(provided, snapshot) => (
                                        // <Tooltip title={"Correct answer hotspot"}>
                                        <Grid item xs={6} container sx={{ marginBottom: "8px" }}>
                                            <VTSidePanelHotspotWrapper
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                onDoubleClick={() =>
                                                    handleDoubleClick(EDraggableOptions.ANSWER, `correct`)
                                                }
                                                sx={{
                                                    width: "100%",
                                                    height: "180px",
                                                    transform:
                                                        (currentMouseOver !==
                                                            EDraggableHotspotId.CORRECT_ANSWER_HOTSPOT &&
                                                            !snapshot.isDragging) ||
                                                        (currentlyDragging && !snapshot.isDragging)
                                                            ? "none !important"
                                                            : "auto",
                                                    ...(snapshot.isDragging && {
                                                        border: `4px dashed ${theme.palette.primary.light}`,
                                                        borderRadius: "16px",
                                                    }),
                                                }}
                                                title={"correct answer hotspot"}
                                                nameCutOffPoint={currentSidePanel.currentWidth > 300 ? 19 : 9}
                                            >
                                                <VTHTMLHotspotRightWrongAnswerToggle answerType="correct" />
                                            </VTSidePanelHotspotWrapper>

                                            {snapshot.isDragging && (
                                                <div
                                                    style={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        width: "100%",
                                                        height: "180px",
                                                        borderRadius: "15px",
                                                        transform: "none !important",
                                                    }}
                                                ></div>
                                            )}
                                        </Grid>
                                    )}
                                </Draggable>
                                // </Tooltip>
                            )}

                            {"incorrect answer hotspot".includes(searchValue.toLowerCase()) && (
                                <Draggable
                                    key={5}
                                    draggableId={EDraggableHotspotId.INCORRECT_ANSWER_HOTSPOT}
                                    index={5}
                                    isDragDisabled={false}
                                >
                                    {(provided, snapshot) => (
                                        // <Tooltip title={"Incorrect answer hotspot"}>
                                        <Grid item xs={6} container sx={{ marginBottom: "8px" }}>
                                            <VTSidePanelHotspotWrapper
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                onDoubleClick={() =>
                                                    handleDoubleClick(EDraggableOptions.ANSWER, `incorrect`)
                                                }
                                                sx={{
                                                    width: "100%",
                                                    height: "180px",
                                                    transform:
                                                        (currentMouseOver !==
                                                            EDraggableHotspotId.INCORRECT_ANSWER_HOTSPOT &&
                                                            !snapshot.isDragging) ||
                                                        (currentlyDragging && !snapshot.isDragging)
                                                            ? "none !important"
                                                            : "auto",
                                                    ...(snapshot.isDragging && {
                                                        border: `4px dashed ${theme.palette.primary.light}`,
                                                        borderRadius: "16px",
                                                    }),
                                                }}
                                                title={"incorrect answer hotspot"}
                                                nameCutOffPoint={currentSidePanel.currentWidth > 300 ? 19 : 9}
                                            >
                                                <VTHTMLHotspotRightWrongAnswerToggle answerType="incorrect" />
                                            </VTSidePanelHotspotWrapper>

                                            {snapshot.isDragging && (
                                                <div
                                                    style={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        width: "100%",
                                                        height: "180px",
                                                        borderRadius: "15px",
                                                        transform: "none !important",
                                                    }}
                                                ></div>
                                            )}
                                        </Grid>
                                    )}
                                </Draggable>
                                // </Tooltip>
                            )}

                            {"continue".includes(searchValue.toLowerCase()) && (
                                <Draggable key={6} draggableId={"continue"} index={6} isDragDisabled={false}>
                                    {(provided, snapshot) => (
                                        <Grid item xs={6} container sx={{ marginBottom: "8px" }}>
                                            <VTSidePanelHotspotWrapper
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                onDoubleClick={() =>
                                                    handleDoubleClick(EDraggableOptions.ANSWER, `continue`)
                                                }
                                                sx={{
                                                    width: "100%",
                                                    height: "180px",
                                                    transform:
                                                        (currentMouseOver !== EDraggableHotspotId.CONTINUE_HOTSPOT &&
                                                            !snapshot.isDragging) ||
                                                        (currentlyDragging && !snapshot.isDragging)
                                                            ? "none !important"
                                                            : "auto",
                                                    ...(snapshot.isDragging && {
                                                        border: `4px dashed ${theme.palette.primary.light}`,
                                                        borderRadius: "16px",
                                                    }),
                                                }}
                                                title={"Continue"}
                                                nameCutOffPoint={currentSidePanel.currentWidth > 300 ? 19 : 9}
                                            >
                                                <VTHTMLHotspot
                                                    onClose={undefined}
                                                    scale2d={0.8}
                                                    title="continue"
                                                    sx={{
                                                        marginTop: "-6px",
                                                        "> div > div": {
                                                            background: "rgba(30,30,30,0.5)",
                                                            cursor: "inherit",
                                                        },
                                                    }}
                                                />
                                            </VTSidePanelHotspotWrapper>

                                            {snapshot.isDragging && (
                                                <div
                                                    style={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        width: "100%",
                                                        height: "180px",
                                                        borderRadius: "15px",
                                                        transform: "none !important",
                                                    }}
                                                ></div>
                                            )}
                                        </Grid>
                                    )}
                                </Draggable>
                            )}

                            {"complete scenario".includes(searchValue.toLowerCase()) && (
                                <Draggable
                                    key={7}
                                    draggableId={EDraggableHotspotId.COMPLETE_SCENARIO_HOTSPOT}
                                    index={7}
                                    isDragDisabled={false}
                                >
                                    {(provided, snapshot) => (
                                        // <Tooltip title={"Complete Scenario hotspot"}>
                                        <Grid item xs={6} container sx={{}}>
                                            <VTSidePanelHotspotWrapper
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                onDoubleClick={() =>
                                                    handleDoubleClick(EDraggableOptions.COMPLETE, `incorrect`)
                                                }
                                                sx={{
                                                    width: "100%",
                                                    height: "180px",
                                                    transform:
                                                        (currentMouseOver !==
                                                            EDraggableHotspotId.COMPLETE_SCENARIO_HOTSPOT &&
                                                            !snapshot.isDragging) ||
                                                        (currentlyDragging && !snapshot.isDragging)
                                                            ? "none !important"
                                                            : "auto",
                                                    ...(snapshot.isDragging && {
                                                        border: `4px dashed ${theme.palette.primary.light}`,
                                                        borderRadius: "16px",
                                                    }),
                                                }}
                                                title={"complete scenario hotspot"}
                                                nameCutOffPoint={currentSidePanel.currentWidth > 300 ? 18 : 8}
                                            >
                                                <VTHTMLHotspot
                                                    // icon="movement"
                                                    onClose={undefined}
                                                    scale2d={0.8}
                                                    title="complete scenario"
                                                    sx={{
                                                        // maxWidth: "none",
                                                        // width: "2500px",
                                                        marginTop: "-6px",
                                                        "> div > div": {
                                                            background: "rgba(30,30,30,0.5)",
                                                            cursor: "inherit",
                                                        },
                                                    }}
                                                />
                                            </VTSidePanelHotspotWrapper>

                                            {snapshot.isDragging && (
                                                <div
                                                    style={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        width: "100%",
                                                        height: "180px",
                                                        borderRadius: "15px",
                                                        transform: "none !important",
                                                    }}
                                                ></div>
                                            )}
                                        </Grid>
                                    )}
                                </Draggable>
                                // </Tooltip>
                            )}

                            {/* {videoCurrentState === "Question" &&
                                "proceed video".includes(searchValue.toLowerCase()) && (
                                    <Tooltip title={"Proceed Video hotspot"}>
                                        <Grid item xs={6} container sx={{}}>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                            >
                                                <img
                                                    alt="incorrect answer hotspot"
                                                    src={`${process.env.PUBLIC_URL}/icons/arrow_right.svg`}
                                                    style={{ width: "80%", cursor: "pointer" }}
                                                    draggable={true}
                                                    onDragStart={() =>
                                                        localHandleDrag(
                                                            true,
                                                            EDraggableOptions.PROCEED,
                                                            `incorrect`,
                                                            ``,
                                                        )
                                                    }
                                                    onDragEnd={() => localHandleDrag(false)}
                                                    onDoubleClick={() =>
                                                        handleDoubleClick(EDraggableOptions.PROCEED, `incorrect`)
                                                    }
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "flex-end",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <Typography>Proceed Video</Typography>
                                            </Grid>
                                        </Grid>
                                    </Tooltip>
                                )} */}

                            {"answer interface".includes(searchValue.toLowerCase()) &&
                                (organisation === "virtus-tech" || organisation === "cardiff-uni") && (
                                    <Tooltip placement="right" title={"User Interface"}>
                                        <Grid item xs={6} container sx={{}}>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                            >
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/images/NoFilesImage.svg`}
                                                    style={{ width: "80%", cursor: "pointer" }}
                                                    draggable={true}
                                                    onDragStart={() =>
                                                        localHandleDrag(
                                                            true,
                                                            EDraggableOptions.INTERFACE,
                                                            `${process.env.PUBLIC_URL}/icons/File.svg`,
                                                            `${process.env.PUBLIC_URL}/icons/File.png`,
                                                        )
                                                    }
                                                    onDragEnd={() => localHandleDrag(false)}
                                                    onDoubleClick={() =>
                                                        handleDoubleClick(
                                                            EDraggableOptions.INTERFACE,
                                                            `${process.env.PUBLIC_URL}/icons/File.svg`,
                                                        )
                                                    }
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "flex-end",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <Typography>Answer Interface</Typography>
                                            </Grid>
                                        </Grid>
                                    </Tooltip>
                                )}
                        </Grid>
                    )}
                </Droppable>
            )}

            {activeButton === 1 && (
                <Grid
                    item
                    container
                    xs={12}
                    columnSpacing={1}
                    sx={{
                        marginTop: "5px",
                        overflowY: "scroll",
                        width: "100%",
                        padding: "20px",
                        height: "calc(100vh - 230px)",
                    }}
                    alignContent={"flex-start"}
                >
                    {videoCurrentState === "Question" ? (
                        <>
                            {questionHotspots && questionHotspots.length > 0 ? (
                                <>
                                    {questionHotspots.map((hotspotElement: IHotspot) => (
                                        <Grid item xs={6} container sx={{ marginBottom: "8px" }}>
                                            {templateLoading === hotspotElement.id ? (
                                                <Skeleton
                                                    height={"300px"}
                                                    width={"100%"}
                                                    sx={{ marginBlock: "-65px" }}
                                                />
                                            ) : (
                                                <VTSidePanelHotspotWrapper
                                                    sx={{
                                                        width: "100%",
                                                        height: "180px",
                                                        borderRadius: "16px",
                                                    }}
                                                    nameCutOffPoint={currentSidePanel.currentWidth > 300 ? 14 : 4}
                                                    title={"Scene Hotspot"}
                                                    primaryArea={
                                                        <>
                                                            <VTButton
                                                                onClick={() => {
                                                                    dispatch(
                                                                        setCurrentHotspot({
                                                                            currentHotspot: hotspotElement,
                                                                            open: true,
                                                                        }),
                                                                    );
                                                                }}
                                                                type="icon"
                                                                sx={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    marginRight: "5px",
                                                                }}
                                                            >
                                                                <SettingsRounded
                                                                    sx={{
                                                                        fontSize: "0.7em",
                                                                        marginLeft: "-1px",
                                                                    }}
                                                                />
                                                            </VTButton>
                                                            <VTButton
                                                                onClick={() => {
                                                                    videoCurrentState === "Question"
                                                                        ? deleteQuestionHotspot({
                                                                              groupId: currentQuestion.id,
                                                                              hotspotId: hotspotElement.id,
                                                                          })
                                                                        : deleteHotspot({
                                                                              sceneId: currentScene.id,
                                                                              hotspotId: hotspotElement.id,
                                                                          });
                                                                }}
                                                                type="icon"
                                                                sx={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    marginRight: "5px",
                                                                }}
                                                            >
                                                                <DeleteRounded sx={{ fontSize: "0.7em" }} />
                                                            </VTButton>
                                                        </>
                                                    }
                                                >
                                                    <VTHTMLHotspot
                                                        // icon="movement"
                                                        onClose={undefined}
                                                        scale2d={
                                                            hotspotElement.contents.preClickContent.contentData
                                                                ? 0.3
                                                                : 0.65
                                                        }
                                                        sx={{
                                                            "> div:nth-child(1) > div": {
                                                                marginTop: hotspotElement.contents.preClickContent
                                                                    .contentData
                                                                    ? "-80px"
                                                                    : 0,
                                                                border: `3px solid ${
                                                                    hotspotElement.styles?.preClickStyles?.colours
                                                                        ?.border &&
                                                                    `rgba(${hotspotElement.styles?.preClickStyles?.colours?.border.r}, ${hotspotElement.styles?.preClickStyles?.colours?.border.g}, ${hotspotElement.styles?.preClickStyles?.colours?.border.b}, ${hotspotElement.styles?.preClickStyles?.colours?.border.a})`
                                                                }`,
                                                                background: `${
                                                                    hotspotElement.styles?.preClickStyles?.colours
                                                                        ?.background
                                                                        ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.background.r}, ${hotspotElement.styles?.preClickStyles?.colours?.background.g}, ${hotspotElement.styles?.preClickStyles?.colours?.background.b}, ${hotspotElement.styles?.preClickStyles?.colours?.background.a})`
                                                                        : "rgba(30,30,30,0.5)"
                                                                } `,
                                                                h3: {
                                                                    color: `${
                                                                        hotspotElement.styles?.preClickStyles?.colours
                                                                            ?.text
                                                                            ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.text.r}, ${hotspotElement.styles?.preClickStyles?.colours?.text.g}, ${hotspotElement.styles?.preClickStyles?.colours?.text.b}, ${hotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                                            : "#fff"
                                                                    } !important`,
                                                                },
                                                            },
                                                            "> div:nth-child(2) > div": {
                                                                background: `${
                                                                    hotspotElement.styles?.preClickStyles?.colours
                                                                        ?.background
                                                                        ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.background.r}, ${hotspotElement.styles?.preClickStyles?.colours?.background.g}, ${hotspotElement.styles?.preClickStyles?.colours?.background.b}, ${hotspotElement.styles?.preClickStyles?.colours?.background.a})`
                                                                        : "rgba(30,30,30,0.5)"
                                                                } `,
                                                                border: `3px solid ${
                                                                    hotspotElement.styles?.preClickStyles?.colours
                                                                        ?.border &&
                                                                    `rgba(${hotspotElement.styles?.preClickStyles?.colours?.border.r}, ${hotspotElement.styles?.preClickStyles?.colours?.border.g}, ${hotspotElement.styles?.preClickStyles?.colours?.border.b}, ${hotspotElement.styles?.preClickStyles?.colours?.border.a})`
                                                                }`,
                                                                div: {
                                                                    color: `${
                                                                        hotspotElement.styles?.preClickStyles?.colours
                                                                            ?.text
                                                                            ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.text.r}, ${hotspotElement.styles?.preClickStyles?.colours?.text.g}, ${hotspotElement.styles?.preClickStyles?.colours?.text.b}, ${hotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                                            : "#fff"
                                                                    }`,
                                                                },
                                                            },
                                                        }}
                                                        title={hotspotElement.contents.preClickContent.title}
                                                        //@ts-ignore
                                                        icon={
                                                            checkIconExistsInIconWrapper(
                                                                hotspotElement.contents.preClickContent.icon,
                                                            ) ? (
                                                                <IconWrapper
                                                                    iconName={
                                                                        hotspotElement.contents.preClickContent
                                                                            .icon as IconName
                                                                    }
                                                                    sx={{
                                                                        height: "80px",
                                                                        width: "80px",
                                                                        color: `${
                                                                            hotspotElement.styles?.preClickStyles
                                                                                ?.colours?.text
                                                                                ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.text.r}, ${hotspotElement.styles?.preClickStyles?.colours?.text.g}, ${hotspotElement.styles?.preClickStyles?.colours?.text.b}, ${hotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                                                : "#fff"
                                                                        }`,
                                                                    }}
                                                                />
                                                            ) : undefined
                                                        }
                                                        vtRichTextEditorContent={
                                                            currentScenario &&
                                                            currentScenario.settings &&
                                                            !currentScenario.settings.vrEnabled &&
                                                            hotspotElement.contents.preClickContent
                                                                ? hotspotElement.contents.preClickContent.contentData &&
                                                                  typeof hotspotElement.contents.preClickContent
                                                                      .contentData === "string" &&
                                                                  hotspotElement.contents.preClickContent.contentData.replace(
                                                                      /\s+/g,
                                                                      "",
                                                                  ) !== `<divclass="rte-container"></div>`
                                                                    ? {
                                                                          content:
                                                                              hotspotElement.contents.preClickContent
                                                                                  .contentData,
                                                                      }
                                                                    : Array.isArray(
                                                                          hotspotElement.contents.preClickContent
                                                                              ?.contentData,
                                                                      )
                                                                    ? {
                                                                          content:
                                                                              hotspotElement.contents.preClickContent
                                                                                  ?.contentData,
                                                                          width: hotspotElement.contents.preClickContent
                                                                              ?.presentationModeContentWidthHeight?.w
                                                                              ? hotspotElement.contents.preClickContent
                                                                                    ?.presentationModeContentWidthHeight
                                                                                    .w
                                                                              : 350,
                                                                          height: hotspotElement.contents
                                                                              .preClickContent
                                                                              ?.presentationModeContentWidthHeight?.h
                                                                              ? hotspotElement.contents.preClickContent
                                                                                    ?.presentationModeContentWidthHeight
                                                                                    .h
                                                                              : 220,
                                                                      }
                                                                    : undefined
                                                                : undefined
                                                        }
                                                    />
                                                </VTSidePanelHotspotWrapper>
                                            )}
                                        </Grid>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img src={NoHotspotTemplates} style={{ width: "80%", cursor: "pointer" }} />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "flex-end",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <Typography align={"center"}>
                                            No scene hotspot, please add some hotspots
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {hotspots &&
                            hotspots.filter(
                                (filterElement: IHotspot) => filterElement.type !== EDraggableOptions.QUESTION,
                            ).length > 0 ? (
                                <>
                                    {hotspots
                                        ?.filter(
                                            (filterElement: IHotspot) =>
                                                filterElement.type !== EDraggableOptions.QUESTION,
                                        )
                                        .map((hotspotElement: IHotspot) => (
                                            <Grid item xs={6} container sx={{ marginBottom: "8px" }}>
                                                {templateLoading === hotspotElement.id ? (
                                                    <Skeleton
                                                        height={"300px"}
                                                        width={"100%"}
                                                        sx={{ marginBlock: "-65px" }}
                                                    />
                                                ) : (
                                                    <VTSidePanelHotspotWrapper
                                                        sx={{
                                                            width: "100%",
                                                            height: "180px",
                                                            borderRadius: "16px",
                                                        }}
                                                        title={"Scene Hotspot"}
                                                        nameCutOffPoint={currentSidePanel.currentWidth > 300 ? 14 : 4}
                                                        primaryArea={
                                                            <>
                                                                <VTButton
                                                                    onClick={() => {
                                                                        dispatch(
                                                                            setCurrentHotspot({
                                                                                currentHotspot: hotspotElement,
                                                                                open: true,
                                                                            }),
                                                                        );
                                                                    }}
                                                                    type="icon"
                                                                    sx={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        marginRight: "5px",
                                                                    }}
                                                                >
                                                                    <SettingsRounded
                                                                        sx={{
                                                                            fontSize: "0.7em",
                                                                            marginLeft: "-1px",
                                                                        }}
                                                                    />
                                                                </VTButton>
                                                                <VTButton
                                                                    onClick={() => {
                                                                        deleteHotspot({
                                                                            sceneId: currentScene.id,
                                                                            hotspotId: hotspotElement.id,
                                                                        });
                                                                    }}
                                                                    type="icon"
                                                                    sx={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        marginRight: "5px",
                                                                    }}
                                                                >
                                                                    <DeleteRounded sx={{ fontSize: "0.7em" }} />
                                                                </VTButton>
                                                            </>
                                                        }
                                                    >
                                                        <VTHTMLHotspot
                                                            // icon="movement"
                                                            onClose={undefined}
                                                            scale2d={
                                                                hotspotElement.contents.preClickContent.contentData
                                                                    ? 0.3
                                                                    : 0.65
                                                            }
                                                            sx={{
                                                                "> div:nth-child(1) > div": {
                                                                    marginTop: hotspotElement.contents.preClickContent
                                                                        .contentData
                                                                        ? "-80px"
                                                                        : 0,
                                                                    border: `3px solid ${
                                                                        hotspotElement.styles?.preClickStyles?.colours
                                                                            ?.border &&
                                                                        `rgba(${hotspotElement.styles?.preClickStyles?.colours?.border.r}, ${hotspotElement.styles?.preClickStyles?.colours?.border.g}, ${hotspotElement.styles?.preClickStyles?.colours?.border.b}, ${hotspotElement.styles?.preClickStyles?.colours?.border.a})`
                                                                    }`,
                                                                    background: `${
                                                                        hotspotElement.styles?.preClickStyles?.colours
                                                                            ?.background
                                                                            ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.background.r}, ${hotspotElement.styles?.preClickStyles?.colours?.background.g}, ${hotspotElement.styles?.preClickStyles?.colours?.background.b}, ${hotspotElement.styles?.preClickStyles?.colours?.background.a})`
                                                                            : "rgba(30,30,30,0.5)"
                                                                    } `,
                                                                    h3: {
                                                                        color: `${
                                                                            hotspotElement.styles?.preClickStyles
                                                                                ?.colours?.text
                                                                                ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.text.r}, ${hotspotElement.styles?.preClickStyles?.colours?.text.g}, ${hotspotElement.styles?.preClickStyles?.colours?.text.b}, ${hotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                                                : "#fff"
                                                                        } !important`,
                                                                    },
                                                                },
                                                                "> div:nth-child(2) > div": {
                                                                    background: `${
                                                                        hotspotElement.styles?.preClickStyles?.colours
                                                                            ?.background
                                                                            ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.background.r}, ${hotspotElement.styles?.preClickStyles?.colours?.background.g}, ${hotspotElement.styles?.preClickStyles?.colours?.background.b}, ${hotspotElement.styles?.preClickStyles?.colours?.background.a})`
                                                                            : "rgba(30,30,30,0.5)"
                                                                    } `,
                                                                    border: `3px solid ${
                                                                        hotspotElement.styles?.preClickStyles?.colours
                                                                            ?.border &&
                                                                        `rgba(${hotspotElement.styles?.preClickStyles?.colours?.border.r}, ${hotspotElement.styles?.preClickStyles?.colours?.border.g}, ${hotspotElement.styles?.preClickStyles?.colours?.border.b}, ${hotspotElement.styles?.preClickStyles?.colours?.border.a})`
                                                                    }`,
                                                                    div: {
                                                                        color: `${
                                                                            hotspotElement.styles?.preClickStyles
                                                                                ?.colours?.text
                                                                                ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.text.r}, ${hotspotElement.styles?.preClickStyles?.colours?.text.g}, ${hotspotElement.styles?.preClickStyles?.colours?.text.b}, ${hotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                                                : "#fff"
                                                                        }`,
                                                                    },
                                                                },
                                                            }}
                                                            title={hotspotElement.contents.preClickContent.title}
                                                            //@ts-ignore
                                                            icon={
                                                                checkIconExistsInIconWrapper(
                                                                    hotspotElement.contents.preClickContent.icon,
                                                                ) ? (
                                                                    <IconWrapper
                                                                        iconName={
                                                                            hotspotElement.contents.preClickContent
                                                                                .icon as IconName
                                                                        }
                                                                        sx={{
                                                                            height: "80px",
                                                                            width: "80px",
                                                                            color: `${
                                                                                hotspotElement.styles?.preClickStyles
                                                                                    ?.colours?.text
                                                                                    ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.text.r}, ${hotspotElement.styles?.preClickStyles?.colours?.text.g}, ${hotspotElement.styles?.preClickStyles?.colours?.text.b}, ${hotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                                                    : "#fff"
                                                                            }`,
                                                                        }}
                                                                    />
                                                                ) : undefined
                                                            }
                                                            vtRichTextEditorContent={
                                                                currentScenario &&
                                                                currentScenario.settings &&
                                                                !currentScenario.settings.vrEnabled &&
                                                                hotspotElement.contents.preClickContent
                                                                    ? hotspotElement.contents.preClickContent
                                                                          .contentData &&
                                                                      typeof hotspotElement.contents.preClickContent
                                                                          .contentData === "string" &&
                                                                      hotspotElement.contents.preClickContent.contentData.replace(
                                                                          /\s+/g,
                                                                          "",
                                                                      ) !== `<divclass="rte-container"></div>`
                                                                        ? {
                                                                              content:
                                                                                  hotspotElement.contents
                                                                                      .preClickContent.contentData,
                                                                          }
                                                                        : Array.isArray(
                                                                              hotspotElement.contents.preClickContent
                                                                                  ?.contentData,
                                                                          )
                                                                        ? {
                                                                              content:
                                                                                  hotspotElement.contents
                                                                                      .preClickContent?.contentData,
                                                                              width: hotspotElement.contents
                                                                                  .preClickContent
                                                                                  ?.presentationModeContentWidthHeight
                                                                                  ?.w
                                                                                  ? hotspotElement.contents
                                                                                        .preClickContent
                                                                                        ?.presentationModeContentWidthHeight
                                                                                        .w
                                                                                  : 350,
                                                                              height: hotspotElement.contents
                                                                                  .preClickContent
                                                                                  ?.presentationModeContentWidthHeight
                                                                                  ?.h
                                                                                  ? hotspotElement.contents
                                                                                        .preClickContent
                                                                                        ?.presentationModeContentWidthHeight
                                                                                        .h
                                                                                  : 220,
                                                                          }
                                                                        : undefined
                                                                    : undefined
                                                            }
                                                        />
                                                    </VTSidePanelHotspotWrapper>
                                                )}
                                            </Grid>
                                        ))}
                                </>
                            ) : (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img src={NoHotspotTemplates} style={{ width: "80%", cursor: "pointer" }} />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "flex-end",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <Typography align={"center"}>
                                            No scene hotspot, please add some hotspots
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                </Grid>
            )}

            {activeButton === 2 && (
                <Droppable droppableId={EDroppableContainerId.HOTSPOTS} type="DROPPABLE_MEDIA">
                    {(provided, snapshot) => (
                        <Grid
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            item
                            container
                            xs={12}
                            columnSpacing={1}
                            sx={{
                                marginTop: "5px",
                                overflowY: "scroll",
                                width: "100%",
                                padding: "20px",
                                height: "calc(100vh - 230px)",
                                transform: "none !important",
                            }}
                            alignContent={"flex-start"}
                        >
                            <>
                                {hotspotTemplates && hotspotTemplates.length > 0 ? (
                                    <>
                                        {hotspotTemplates?.map((hotspotElement: IHotspot, templateIndex: number) => {
                                            return (
                                                <Draggable
                                                    key={hotspotElement.id}
                                                    draggableId={hotspotElement.id}
                                                    index={templateIndex}
                                                    isDragDisabled={false}
                                                >
                                                    {(provided, snapshot) => (
                                                        // <Tooltip title={"Answer hotspot"}>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            container
                                                            sx={{ marginBottom: "8px" }}
                                                            onMouseDown={() => {
                                                                dispatch(
                                                                    setCurrentHotspotTransfer({
                                                                        create: false,
                                                                        hotspot: { ...hotspotElement, id: uuid() },
                                                                    }),
                                                                );
                                                            }}
                                                        >
                                                            {templateLoading === hotspotElement.id ? (
                                                                <Skeleton
                                                                    height={"300px"}
                                                                    width={"100%"}
                                                                    sx={{ marginBlock: "-65px" }}
                                                                />
                                                            ) : (
                                                                <>
                                                                    <VTSidePanelHotspotWrapper
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={provided.innerRef}
                                                                        onDoubleClick={() =>
                                                                            dispatch(
                                                                                setCurrentHotspotTransfer({
                                                                                    create: true,
                                                                                    hotspot: {
                                                                                        ...hotspotElement,
                                                                                        id: uuid(),
                                                                                    },
                                                                                }),
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            width: "100%",
                                                                            height: "180px",
                                                                            ...(snapshot.isDragging && {
                                                                                border: `4px dashed ${theme.palette.primary.light}`,
                                                                                borderRadius: "16px",
                                                                            }),
                                                                        }}
                                                                        title={"Custom Hotspot"}
                                                                        nameCutOffPoint={
                                                                            currentSidePanel.currentWidth > 300 ? 14 : 4
                                                                        }
                                                                        primaryArea={
                                                                            <>
                                                                                <VTButton
                                                                                    onClick={() => {
                                                                                        dispatch(
                                                                                            setCurrentHotspot({
                                                                                                currentHotspot:
                                                                                                    hotspotElement,
                                                                                                open: true,
                                                                                            }),
                                                                                        );
                                                                                    }}
                                                                                    type="icon"
                                                                                    sx={{
                                                                                        width: "20px",
                                                                                        height: "20px",
                                                                                        marginRight: "5px",
                                                                                    }}
                                                                                >
                                                                                    <SettingsRounded
                                                                                        sx={{
                                                                                            fontSize: "0.7em",
                                                                                            marginLeft: "-1px",
                                                                                        }}
                                                                                    />
                                                                                </VTButton>
                                                                                <VTButton
                                                                                    onClick={() => {
                                                                                        deleteQuestionHotspot({
                                                                                            groupId: userId,
                                                                                            hotspotId:
                                                                                                hotspotElement.id,
                                                                                        });
                                                                                        setTemplateLoading(
                                                                                            hotspotElement.id,
                                                                                        );
                                                                                    }}
                                                                                    type="icon"
                                                                                    sx={{
                                                                                        width: "20px",
                                                                                        height: "20px",
                                                                                        marginRight: "5px",
                                                                                    }}
                                                                                >
                                                                                    <DeleteRounded
                                                                                        sx={{ fontSize: "0.7em" }}
                                                                                    />
                                                                                </VTButton>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <VTHTMLHotspot
                                                                            // icon="movement"
                                                                            onClose={undefined}
                                                                            scale2d={
                                                                                hotspotElement.contents.preClickContent
                                                                                    .contentData
                                                                                    ? 0.3
                                                                                    : 0.65
                                                                            }
                                                                            sx={{
                                                                                "> div:nth-child(1) > div": {
                                                                                    marginTop: hotspotElement.contents
                                                                                        .preClickContent.contentData
                                                                                        ? "-80px"
                                                                                        : 0,
                                                                                    border: `3px solid ${
                                                                                        hotspotElement.styles
                                                                                            ?.preClickStyles?.colours
                                                                                            ?.border &&
                                                                                        `rgba(${hotspotElement.styles?.preClickStyles?.colours?.border.r}, ${hotspotElement.styles?.preClickStyles?.colours?.border.g}, ${hotspotElement.styles?.preClickStyles?.colours?.border.b}, ${hotspotElement.styles?.preClickStyles?.colours?.border.a})`
                                                                                    }`,
                                                                                    background: `${
                                                                                        hotspotElement.styles
                                                                                            ?.preClickStyles?.colours
                                                                                            ?.background
                                                                                            ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.background.r}, ${hotspotElement.styles?.preClickStyles?.colours?.background.g}, ${hotspotElement.styles?.preClickStyles?.colours?.background.b}, ${hotspotElement.styles?.preClickStyles?.colours?.background.a})`
                                                                                            : "rgba(30,30,30,0.5)"
                                                                                    } `,
                                                                                    h3: {
                                                                                        color: `${
                                                                                            hotspotElement.styles
                                                                                                ?.preClickStyles
                                                                                                ?.colours?.text
                                                                                                ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.text.r}, ${hotspotElement.styles?.preClickStyles?.colours?.text.g}, ${hotspotElement.styles?.preClickStyles?.colours?.text.b}, ${hotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                                                                : "#fff"
                                                                                        } !important`,
                                                                                    },
                                                                                },
                                                                                "> div:nth-child(2) > div": {
                                                                                    background: `${
                                                                                        hotspotElement.styles
                                                                                            ?.preClickStyles?.colours
                                                                                            ?.background
                                                                                            ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.background.r}, ${hotspotElement.styles?.preClickStyles?.colours?.background.g}, ${hotspotElement.styles?.preClickStyles?.colours?.background.b}, ${hotspotElement.styles?.preClickStyles?.colours?.background.a})`
                                                                                            : "rgba(30,30,30,0.5)"
                                                                                    } `,
                                                                                    border: `3px solid ${
                                                                                        hotspotElement.styles
                                                                                            ?.preClickStyles?.colours
                                                                                            ?.border &&
                                                                                        `rgba(${hotspotElement.styles?.preClickStyles?.colours?.border.r}, ${hotspotElement.styles?.preClickStyles?.colours?.border.g}, ${hotspotElement.styles?.preClickStyles?.colours?.border.b}, ${hotspotElement.styles?.preClickStyles?.colours?.border.a})`
                                                                                    }`,
                                                                                    div: {
                                                                                        color: `${
                                                                                            hotspotElement.styles
                                                                                                ?.preClickStyles
                                                                                                ?.colours?.text
                                                                                                ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.text.r}, ${hotspotElement.styles?.preClickStyles?.colours?.text.g}, ${hotspotElement.styles?.preClickStyles?.colours?.text.b}, ${hotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                                                                : "#fff"
                                                                                        }`,
                                                                                    },
                                                                                },
                                                                            }}
                                                                            title={
                                                                                hotspotElement.contents.preClickContent
                                                                                    .title
                                                                            }
                                                                            //@ts-ignore
                                                                            icon={
                                                                                checkIconExistsInIconWrapper(
                                                                                    hotspotElement.contents
                                                                                        .preClickContent.icon,
                                                                                ) ? (
                                                                                    <IconWrapper
                                                                                        iconName={
                                                                                            hotspotElement.contents
                                                                                                .preClickContent
                                                                                                .icon as IconName
                                                                                        }
                                                                                        sx={{
                                                                                            height: "80px",
                                                                                            width: "80px",
                                                                                            color: `${
                                                                                                hotspotElement.styles
                                                                                                    ?.preClickStyles
                                                                                                    ?.colours?.text
                                                                                                    ? `rgba(${hotspotElement.styles?.preClickStyles?.colours?.text.r}, ${hotspotElement.styles?.preClickStyles?.colours?.text.g}, ${hotspotElement.styles?.preClickStyles?.colours?.text.b}, ${hotspotElement.styles?.preClickStyles?.colours?.text.a})`
                                                                                                    : "#fff"
                                                                                            }`,
                                                                                        }}
                                                                                    />
                                                                                ) : undefined
                                                                            }
                                                                            vtRichTextEditorContent={
                                                                                currentScenario &&
                                                                                currentScenario.settings &&
                                                                                !currentScenario.settings.vrEnabled &&
                                                                                hotspotElement.contents.preClickContent
                                                                                    ? hotspotElement.contents
                                                                                          .preClickContent
                                                                                          .contentData &&
                                                                                      typeof hotspotElement.contents
                                                                                          .preClickContent
                                                                                          .contentData === "string" &&
                                                                                      hotspotElement.contents.preClickContent.contentData.replace(
                                                                                          /\s+/g,
                                                                                          "",
                                                                                      ) !==
                                                                                          `<divclass="rte-container"></div>`
                                                                                        ? {
                                                                                              content:
                                                                                                  hotspotElement
                                                                                                      .contents
                                                                                                      .preClickContent
                                                                                                      .contentData,
                                                                                          }
                                                                                        : Array.isArray(
                                                                                              hotspotElement.contents
                                                                                                  .preClickContent
                                                                                                  ?.contentData,
                                                                                          )
                                                                                        ? {
                                                                                              content:
                                                                                                  hotspotElement
                                                                                                      .contents
                                                                                                      .preClickContent
                                                                                                      ?.contentData,
                                                                                              width: hotspotElement
                                                                                                  .contents
                                                                                                  .preClickContent
                                                                                                  ?.presentationModeContentWidthHeight
                                                                                                  ?.w
                                                                                                  ? hotspotElement
                                                                                                        .contents
                                                                                                        .preClickContent
                                                                                                        ?.presentationModeContentWidthHeight
                                                                                                        .w
                                                                                                  : 350,
                                                                                              height: hotspotElement
                                                                                                  .contents
                                                                                                  .preClickContent
                                                                                                  ?.presentationModeContentWidthHeight
                                                                                                  ?.h
                                                                                                  ? hotspotElement
                                                                                                        .contents
                                                                                                        .preClickContent
                                                                                                        ?.presentationModeContentWidthHeight
                                                                                                        .h
                                                                                                  : 220,
                                                                                          }
                                                                                        : undefined
                                                                                    : undefined
                                                                            }
                                                                        />
                                                                    </VTSidePanelHotspotWrapper>
                                                                    {snapshot.isDragging && (
                                                                        <div
                                                                            style={{
                                                                                backgroundColor:
                                                                                    theme.palette.background.paper,
                                                                                width: "100%",
                                                                                height: "180px",
                                                                                borderRadius: "15px",
                                                                                transform: "none !important",
                                                                            }}
                                                                        ></div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Grid>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <>
                                        {" "}
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <img src={NoHotspotTemplates} style={{ width: "80%", cursor: "pointer" }} />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "flex-end",
                                                marginTop: "10px",
                                            }}
                                        >
                                            <Typography align={"center"}>
                                                No saved templates, please create a template from the hotspot editor
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                            </>
                        </Grid>
                    )}
                </Droppable>
            )}
        </Grid>
    );
}
