import { useState } from "react";
import { Grid, ImageList, ImageListItem, Skeleton, SxProps, Tooltip, Typography, useTheme } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
    VTButton,
    VTTextField,
    VTHTMLHotspot,
    VTSidePanelHotspotWrapper,
    VTHTMLHotspotRightWrongAnswerToggle,
    VTSidePanelTextHotspotWrapper,
} from "@virtus-tech-repository/virtus-tech-repository";
import { EDraggableOptions, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { Preview, RadioButtonCheckedRounded } from "@mui/icons-material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Draggable, Droppable, Position } from "react-beautiful-dnd";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";
import {
    EDraggableHotspotId,
    EDroppableContainerId,
} from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
import { useGetGroupHotspotsQuery, useGetHotspotsQuery } from "../../services/hotspot.service";

interface IProps {
    sideDrawWidth: number;
    sx?: SxProps;
    handleDragStart?: (mediaType: EDraggableOptions, src: string, preview?: string) => void;
    handleDragStop?: () => void;
    videoCurrentState: "Paused" | "Playing" | "Question" | undefined;
}

export default function VTTextSidePanel({
    sideDrawWidth,
    handleDragStart,
    handleDragStop,
    sx,
    videoCurrentState,
}: IProps) {
    const { currentScene, currentlyDragging, currentSidePanel } = useAppSelector((state) => state.currentReducer);
    const { id: userId } = useAppSelector((state) => state.userReducer);
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const { data: hotspotTemplates } = useGetGroupHotspotsQuery(userId);
    const { data: hotspots } = useGetHotspotsQuery(currentScene.id);
    const [templateLoading, setTemplateLoading] = useState<string>("");
    const [currentMouseOver, setCurrentMouseOver] = useState<string>("");

    const theme = useTheme();

    console.log("side draw woodth", sideDrawWidth);

    return (
        <Grid container xs={12} justifyContent={"center"} sx={{ height: "100%", ...sx }}>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Images search */}
            <Grid item xs={11}>
                <VTTextField
                    placeholder={"Search Hotspots"}
                    sx={{
                        marginTop: "20px",
                    }}
                    value={searchValue}
                    onChange={(textInput: string) => setSearchValue(textInput)}
                    type={"text"}
                    endIcon={<SearchRoundedIcon />}
                />
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Text list */}
            <Droppable droppableId={EDroppableContainerId.HOTSPOTS} type="DROPPABLE_MEDIA">
                {(provided, snapshot) => (
                    <Grid
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        item
                        container
                        xs={12}
                        columnSpacing={1}
                        sx={{
                            marginTop: "5px",
                            overflowY: "scroll",
                            width: "100%",
                            padding: "20px",
                            height: "100%",
                        }}
                        alignContent={"flex-start"}
                    >
                        {"paragraph text hotspot".includes(searchValue.toLowerCase()) && (
                            <Draggable
                                key={0}
                                draggableId={EDraggableHotspotId.TEXT_HOTSPOT_PARAGRAPH}
                                index={0}
                                isDragDisabled={false}
                            >
                                {(provided, snapshot) => (
                                    <>
                                        <Grid
                                            item
                                            xs={6}
                                            container
                                            sx={{ marginBottom: "8px" }}
                                            onMouseEnter={() => {
                                                setCurrentMouseOver(EDraggableHotspotId.TEXT_HOTSPOT_PARAGRAPH);
                                            }}
                                        >
                                            <VTSidePanelTextHotspotWrapper
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                sx={{
                                                    width: "100%",
                                                    height: "180px",
                                                    transform:
                                                        (currentMouseOver !==
                                                            EDraggableHotspotId.TEXT_HOTSPOT_PARAGRAPH &&
                                                            !snapshot.isDragging) ||
                                                        (currentlyDragging && !snapshot.isDragging)
                                                            ? "none !important"
                                                            : "auto",
                                                    ...(snapshot.isDragging && {
                                                        border: `4px dashed ${theme.palette.primary.light}`,
                                                        borderRadius: "16px",
                                                    }),
                                                }}
                                                title={"Paragraph Text Hotspot"}
                                                nameCutOffPoint={currentSidePanel.currentWidth > 300 ? 18 : 8}
                                            >
                                                <VTHTMLHotspot
                                                    onClose={undefined}
                                                    scale2d={0.8}
                                                    vtRichTextEditorContent={{
                                                        // @ts-ignore
                                                        content: "Paragraph",
                                                    }}
                                                    sx={{
                                                        marginTop: "-6px",
                                                        "> div": { cursor: "grab" },
                                                    }}
                                                />
                                            </VTSidePanelTextHotspotWrapper>
                                            {snapshot.isDragging && (
                                                <div
                                                    style={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        width: "100%",
                                                        height: "180px",
                                                        borderRadius: "15px",
                                                        transform: "none !important",
                                                    }}
                                                ></div>
                                            )}
                                        </Grid>
                                    </>
                                )}
                            </Draggable>
                        )}
                        {"bullet points text hotspot".includes(searchValue.toLowerCase()) && (
                            <Draggable
                                key={1}
                                draggableId={EDraggableHotspotId.TEXT_HOTSPOT_BULLET_POINTS}
                                index={1}
                                isDragDisabled={false}
                            >
                                {(provided, snapshot) => (
                                    <>
                                        <Grid
                                            item
                                            xs={6}
                                            container
                                            sx={{ marginBottom: "8px" }}
                                            onMouseEnter={() => {
                                                setCurrentMouseOver(EDraggableHotspotId.TEXT_HOTSPOT_BULLET_POINTS);
                                            }}
                                        >
                                            <VTSidePanelTextHotspotWrapper
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                sx={{
                                                    width: "100%",
                                                    height: "180px",
                                                    transform:
                                                        (currentMouseOver !==
                                                            EDraggableHotspotId.TEXT_HOTSPOT_BULLET_POINTS &&
                                                            !snapshot.isDragging) ||
                                                        (currentlyDragging && !snapshot.isDragging)
                                                            ? "none !important"
                                                            : "auto",
                                                    ...(snapshot.isDragging && {
                                                        border: `4px dashed ${theme.palette.primary.light}`,
                                                        borderRadius: "16px",
                                                    }),
                                                }}
                                                title={"Bullet Points Text Hotspot"}
                                                nameCutOffPoint={currentSidePanel.currentWidth > 275 ? 18 : 5}
                                            >
                                                <VTHTMLHotspot
                                                    onClose={undefined}
                                                    scale2d={0.8}
                                                    vtRichTextEditorContent={{
                                                        // @ts-ignore
                                                        content:
                                                            sideDrawWidth <= 275
                                                                ? `<div class="rte-container"> <ul><li>a</li><li>b</li><li>c</li></ul></div>`
                                                                : `<div class="rte-container"> <ul><li>bullet a</li><li>bullet b</li><li>bullet c&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</li></ul></div>`,
                                                    }}
                                                    sx={{
                                                        marginTop: "-10px",
                                                        "> div": { cursor: "grab" },
                                                    }}
                                                />
                                            </VTSidePanelTextHotspotWrapper>
                                            {snapshot.isDragging && (
                                                <div
                                                    style={{
                                                        backgroundColor: theme.palette.background.paper,
                                                        width: "100%",
                                                        height: "180px",
                                                        borderRadius: "15px",
                                                        transform: "none !important",
                                                    }}
                                                ></div>
                                            )}
                                        </Grid>
                                    </>
                                )}
                            </Draggable>
                        )}
                    </Grid>
                )}
            </Droppable>
        </Grid>
    );
}
