//-----------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";

// Material ui Imports
import { useEffect, useState } from "react";
import { VTButton, VTCanvas, VTCube, VTDialog, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";
import {
    IScenario,
    IScene,
    IThreeSixtyVideoContext,
} from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { Grid, MenuItem, Select, Slider, SxProps, TextField, Tooltip, alpha, useTheme } from "@mui/material";
import {
    LoopRounded,
    DoNotDisturbAltRounded,
    VolumeUpRounded,
    VolumeOffRounded,
    AddRounded,
    CancelRounded,
    SaveRounded,
    DeleteRounded,
    SettingsRounded,
    FastRewindRounded,
    FastForwardRounded,
    PlayArrowRounded,
    PlayDisabledRounded,
    PauseRounded,
    TimerRounded,
    TimerOffRounded,
    InfoRounded,
} from "@mui/icons-material";
import VTSphere from "./VTSphere";
import { getTimeElapsedInStopWatchFormatString } from "../../utils/getTimeElapsedInStopWatchFormattedString";
import { useDispatch } from "react-redux";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import DoDisturbOnRoundedIcon from "@mui/icons-material/DoDisturbOnRounded";
import DoDisturbOffRoundedIcon from "@mui/icons-material/DoDisturbOffRounded";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { iImageProps } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCube";
// Custom components imports (and hooks and helpers)

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
export interface iVideoSettingsProps {
    scene: IScene;
    scenes: IScene[];
    dialogOpen: string;
    setDialogOpen: (dialogOpen: string | undefined) => void;
    handelSettingSave: (settingsData: IThreeSixtyVideoContext) => void;
    sx?: SxProps;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// VTSphere for immersive videos
export default function VTSceneSettings({
    scene,
    scenes,
    dialogOpen,
    setDialogOpen,
    handelSettingSave,
    sx,
}: iVideoSettingsProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const theme = useTheme();
    const dispatch = useDispatch();
    //-------------------------------------------------------------------------------------------------
    // ## USE STATE, USE MEMO ##

    const [video, setVideo] = useState<HTMLVideoElement>(document.createElement("video"));
    const [videoCurrentTime, setVideoCurrentTime] = useState<number>(0);
    const [videoCurrentlyPause, setVideoCurrentlyPause] = useState<boolean>(true);

    const [videoStartTime, setVideoStartTime] = useState<number>(0);
    const [videoEndTime, setVideoEndTime] = useState<number>(0);
    const [videoAutoLoop, setVideoAutoLoop] = useState<boolean>(false);
    const [videoDefaultMuted, setVideoDefaultMuted] = useState<boolean>(false);
    const [videoUserControls, setVideoUserControls] = useState<boolean>(false);
    const [autoCloseHotspot, setAutoCloseHotspot] = useState<boolean>(false);
    const [videoMoveToScene, setVideoMoveToScene] = useState<string>("");
    const [videoMoveToSceneDelay, setVideoMoveToSceneDelay] = useState<number>(0);
    const [currentImageTextures, setCurrentImageTextures] = useState<iImageProps | undefined>(undefined);

    useEffect(() => {
        if (scene) {
            if (scene.context.autoCloseHotspot) {
                setAutoCloseHotspot(scene.context.autoCloseHotspot);
            }
            if (scene.mediaType === EDraggableOptions.IMMERSIVE_IMAGE) {
                const loader = new THREE.TextureLoader();

                // @ts-ignore
                const backTexture = loader.load(scene.src + "/b.jpg");
                backTexture.encoding = THREE.sRGBEncoding;

                // @ts-ignore
                const frontTexture = loader.load(scene.src + "/f.jpg");
                frontTexture.encoding = THREE.sRGBEncoding;

                // @ts-ignore
                const leftTexture = loader.load(scene.src + "/l.jpg");
                leftTexture.encoding = THREE.sRGBEncoding;

                // @ts-ignore
                const rightTexture = loader.load(scene.src + "/r.jpg");
                rightTexture.encoding = THREE.sRGBEncoding;

                // @ts-ignore
                const upTexture = loader.load(scene.src + "/u.jpg");
                upTexture.encoding = THREE.sRGBEncoding;

                // @ts-ignore
                const downTexture = loader.load(scene.src + "/d.jpg");
                downTexture.encoding = THREE.sRGBEncoding;

                setCurrentImageTextures({
                    left: leftTexture,
                    right: rightTexture,
                    up: upTexture,
                    down: downTexture,
                    front: frontTexture,
                    back: backTexture,
                });
            } else {
                const currentContext = scene.context as IThreeSixtyVideoContext;
                if (currentContext.startTime) {
                    setVideoStartTime(currentContext.startTime);
                }

                if (currentContext.endTime) {
                    setVideoEndTime(currentContext.endTime);
                }
                if (currentContext.loopable) {
                    setVideoAutoLoop(currentContext.loopable);
                }
                if (currentContext.muted) {
                    setVideoDefaultMuted(currentContext.muted);
                }
                if (currentContext.moveToScene) {
                    setVideoMoveToScene(currentContext.moveToScene);
                }
                if (currentContext.moveToSceneDelay) {
                    setVideoMoveToSceneDelay(currentContext.moveToSceneDelay);
                }

                if (currentContext.autoCloseHotspot) {
                    setAutoCloseHotspot(currentContext.autoCloseHotspot);
                }
            }
        }
    }, [scene]);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    // Creates the video
    const iconStyles = {
        background: theme.palette.primary.main,
        borderRadius: "50%",
        padding: "4px",
        color: "#fff",
        "&:hover": {
            background: theme.palette.primary.dark,
        },
    };

    let SliderZone = () => {
        return (
            <Slider
                sx={{
                    width: "90%",
                    zIndex: 1000,
                }}
                value={videoCurrentTime}
                max={video.duration}
                onChange={(_, value) => {
                    video.currentTime = value as number;
                    setVideoCurrentTime(value as number);
                }}
                step={0.001}
            />
        );
    };

    //-------------------------------------------------------------------------------------------------
    // ## Render ##
    // Returns default cube
    return (
        <VTDialog
            open={dialogOpen !== undefined}
            handleClose={() => {
                setDialogOpen(undefined);
                dispatch(
                    setCurrentSidePanel({
                        currentTab: undefined,
                        currentWidth: 0,
                        option: "",
                    }),
                );
            }}
            sx={sx}
            title={"Scene Setting"}
            primaryArea={
                <>
                    <VTButton
                        onClick={() => {
                            setDialogOpen(undefined);
                            dispatch(
                                setCurrentSidePanel({
                                    currentTab: undefined,
                                    currentWidth: 0,
                                    option: "",
                                }),
                            );
                        }}
                    >
                        Cancel
                    </VTButton>
                    <VTButton
                        onClick={() => {
                            handelSettingSave({
                                startTime: videoStartTime,
                                endTime: videoEndTime,
                                quality: "",
                                loopable: videoAutoLoop,
                                muted: videoDefaultMuted,
                                moveToScene: videoMoveToScene,
                                moveToSceneDelay: videoMoveToSceneDelay,
                                autoCloseHotspot: autoCloseHotspot,
                            });
                            setDialogOpen(undefined);
                            dispatch(
                                setCurrentSidePanel({
                                    currentTab: undefined,
                                    currentWidth: 0,
                                    option: "",
                                }),
                            );
                        }}
                    >
                        Save
                    </VTButton>
                </>
            }
        >
            <Grid container sx={{ width: "100%", paddingTop: "40px" }} justifyContent={"center"} spacing={2}>
                <VTCanvas
                    debug={false}
                    style={{
                        width: "80%",
                        height: "400px",
                        borderRadius: "15px",
                    }}
                    limitZoom={true}
                >
                    {scene.mediaType === EDraggableOptions.VIDEO ||
                    scene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO ? (
                        <VTSphere
                            video={video}
                            setVideo={setVideo}
                            videoSrc={scene.src}
                            setVideoCurrentTime={setVideoCurrentTime}
                        />
                    ) : (
                        <VTCube image={currentImageTextures} />
                    )}
                </VTCanvas>
                {scene.mediaType === EDraggableOptions.VIDEO ||
                    (scene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO && (
                        <Grid
                            container
                            sx={{ width: "80%", height: "80px", marginTop: "-90px", zIndex: 50 }}
                            justifyContent={"center"}
                        >
                            <Grid item container xs={12} justifyContent={"center"}>
                                <SliderZone />
                            </Grid>
                            <Grid item container xs={12} justifyContent={"center"}>
                                <VTButton
                                    sx={{ marginRight: "5px" }}
                                    type={"icon"}
                                    onClick={() => {
                                        video.currentTime = videoCurrentTime - 5;
                                    }}
                                    disabled={videoCurrentTime <= 5 && true}
                                >
                                    <FastRewindRounded />
                                </VTButton>
                                {/*-----------------------------------------------------------------------------*/}
                                {/* Play / Pause controls */}
                                {!videoCurrentlyPause ? (
                                    <VTButton
                                        sx={{ marginRight: "5px" }}
                                        type={"icon"}
                                        onClick={() => {
                                            setVideoCurrentlyPause(true);
                                            video.pause();
                                        }}
                                    >
                                        <PauseRounded />
                                    </VTButton>
                                ) : (
                                    <VTButton
                                        sx={{ marginRight: "5px" }}
                                        type={"icon"}
                                        onClick={() => {
                                            setVideoCurrentlyPause(false);
                                            video.play();
                                        }}
                                    >
                                        <PlayArrowRounded />
                                    </VTButton>
                                )}
                                <VTButton
                                    sx={{ marginRight: "27px" }}
                                    type={"icon"}
                                    onClick={() => {
                                        video.currentTime = videoCurrentTime + 5;
                                    }}
                                    disabled={video.duration - videoCurrentTime <= 5 && true}
                                >
                                    <FastForwardRounded />
                                </VTButton>
                                <div
                                    style={{
                                        fontFamily: "Digit",
                                        fontSize: "0.9em",
                                        marginTop: "12px",
                                    }}
                                >
                                    {getTimeElapsedInStopWatchFormatString(videoCurrentTime * 1000)}
                                </div>
                                <span
                                    style={{
                                        fontSize: "1.3",
                                        marginLeft: "6px",
                                        marginRight: "2px",
                                        marginTop: "14px",
                                    }}
                                >
                                    /
                                </span>
                                <div style={{ fontFamily: "Digit", fontSize: "0.9em", marginTop: "12px" }}>
                                    {video.duration
                                        ? getTimeElapsedInStopWatchFormatString(video.duration * 1000)
                                        : "00:00:00"}
                                </div>
                            </Grid>
                        </Grid>
                    ))}

                <Grid container item xs={10} justifyContent={"center"}>
                    {scene.mediaType === EDraggableOptions.VIDEO ||
                        (scene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO && (
                            <Grid item xs={2} justifyContent={"center"}>
                                <VTTypography align="center">AutoLoop</VTTypography>

                                <Tooltip title={"Can not be enable while move to scene is defined"}>
                                    <Grid item container justifyContent={"center"} xs={12}>
                                        <VTButton
                                            onClick={() => {
                                                setVideoAutoLoop(!videoAutoLoop);
                                            }}
                                            type="icon"
                                            disabled={videoMoveToScene !== ""}
                                            sx={{
                                                width: "70px",
                                                background: videoAutoLoop
                                                    ? alpha(theme.palette.primary.dark, 0.5)
                                                    : alpha(theme.palette.primary.dark, 0.2),
                                                paddingRight: videoAutoLoop ? "8px" : "40px",
                                                paddingLeft: videoAutoLoop ? "40px" : "8px",
                                                // transition: "0.5s",
                                                "&:hover": {
                                                    background: videoAutoLoop
                                                        ? alpha(theme.palette.primary.dark, 0.6)
                                                        : alpha(theme.palette.primary.dark, 0.3),
                                                },
                                            }}
                                        >
                                            {videoAutoLoop ? (
                                                <LoopRounded sx={iconStyles} />
                                            ) : (
                                                <DoNotDisturbAltRounded sx={iconStyles} />
                                            )}
                                        </VTButton>
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        ))}

                    {scene.mediaType === EDraggableOptions.VIDEO ||
                        (scene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO && (
                            <Grid item xs={2} justifyContent={"center"}>
                                <VTTypography align="center">
                                    Default Muted{" "}
                                    <Tooltip title={"Muted in viewer mode"}>
                                        <InfoRounded sx={{ fontSize: "1em" }} />
                                    </Tooltip>
                                </VTTypography>

                                <Grid item container justifyContent={"center"} xs={12}>
                                    <VTButton
                                        onClick={() => {
                                            setVideoDefaultMuted(!videoDefaultMuted);
                                        }}
                                        type="icon"
                                        sx={{
                                            width: "70px",
                                            background: videoDefaultMuted
                                                ? alpha(theme.palette.primary.dark, 0.5)
                                                : alpha(theme.palette.primary.dark, 0.2),
                                            paddingRight: videoDefaultMuted ? "8px" : "40px",
                                            paddingLeft: videoDefaultMuted ? "40px" : "8px",
                                            // transition: "0.5s",
                                            "&:hover": {
                                                background: videoDefaultMuted
                                                    ? alpha(theme.palette.primary.dark, 0.6)
                                                    : alpha(theme.palette.primary.dark, 0.3),
                                            },
                                        }}
                                    >
                                        {videoDefaultMuted ? (
                                            <VolumeOffRounded sx={iconStyles} />
                                        ) : (
                                            <VolumeUpRounded sx={iconStyles} />
                                        )}
                                    </VTButton>
                                </Grid>
                            </Grid>
                        ))}

                    {scene.mediaType === EDraggableOptions.VIDEO ||
                        (scene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO && (
                            <Grid item xs={2} justifyContent={"center"}>
                                <VTTypography align="center">
                                    User controls
                                    <Tooltip title={"Controls restricted in viewer mode"}>
                                        <InfoRounded sx={{ fontSize: "1em" }} />
                                    </Tooltip>
                                </VTTypography>

                                <Grid item container justifyContent={"center"} xs={12}>
                                    <VTButton
                                        onClick={() => {
                                            setVideoUserControls(!videoUserControls);
                                        }}
                                        type="icon"
                                        sx={{
                                            width: "70px",
                                            background: videoUserControls
                                                ? alpha(theme.palette.primary.dark, 0.5)
                                                : alpha(theme.palette.primary.dark, 0.2),
                                            paddingRight: videoUserControls ? "8px" : "40px",
                                            paddingLeft: videoUserControls ? "40px" : "8px",
                                            // transition: "0.5s",
                                            "&:hover": {
                                                background: videoUserControls
                                                    ? alpha(theme.palette.primary.dark, 0.6)
                                                    : alpha(theme.palette.primary.dark, 0.3),
                                            },
                                        }}
                                    >
                                        {videoUserControls ? (
                                            <PlayArrowRounded sx={iconStyles} />
                                        ) : (
                                            <PlayDisabledRounded sx={iconStyles} />
                                        )}
                                    </VTButton>
                                </Grid>
                            </Grid>
                        ))}

                    <Grid item xs={3} justifyContent={"center"}>
                        <VTTypography align="center">
                            Auto Close Hotspots
                            <Tooltip title={"Only visable in viewer and preview"}>
                                <InfoRounded sx={{ fontSize: "1em" }} />
                            </Tooltip>
                        </VTTypography>

                        <Grid item container justifyContent={"center"} xs={12}>
                            <VTButton
                                onClick={() => {
                                    setAutoCloseHotspot(!autoCloseHotspot);
                                }}
                                type="icon"
                                sx={{
                                    width: "70px",
                                    background: autoCloseHotspot
                                        ? alpha(theme.palette.primary.dark, 0.5)
                                        : alpha(theme.palette.primary.dark, 0.2),
                                    paddingRight: autoCloseHotspot ? "8px" : "40px",
                                    paddingLeft: autoCloseHotspot ? "40px" : "8px",
                                    // transition: "0.5s",
                                    "&:hover": {
                                        background: autoCloseHotspot
                                            ? alpha(theme.palette.primary.dark, 0.6)
                                            : alpha(theme.palette.primary.dark, 0.3),
                                    },
                                }}
                            >
                                {autoCloseHotspot ? (
                                    <DoDisturbOnRoundedIcon sx={iconStyles} />
                                ) : (
                                    <DoDisturbOffRoundedIcon sx={iconStyles} />
                                )}
                            </VTButton>
                        </Grid>
                    </Grid>
                </Grid>

                {scene.mediaType === EDraggableOptions.VIDEO ||
                    (scene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO && (
                        <Grid item xs={7} justifyContent={"center"} sx={{ marginLeft: "25px" }}>
                            Move to Scene on video completed
                            <Tooltip
                                title={"Can not be used while autoloop is enabled"}
                                disableHoverListener={!videoAutoLoop}
                            >
                                <Select
                                    value={videoMoveToScene}
                                    onChange={(e) => setVideoMoveToScene(e.target.value)}
                                    sx={{ width: "100%" }}
                                    disabled={videoAutoLoop}
                                >
                                    {scenes
                                        .filter((sceneElement: IScene) => sceneElement.id !== scene.id)
                                        .map((mappedScene: IScene) => (
                                            <MenuItem value={mappedScene.id}>{mappedScene.name}</MenuItem>
                                        ))}
                                    <MenuItem value={""}>{"No scene"}</MenuItem>
                                </Select>
                            </Tooltip>
                        </Grid>
                    ))}

                {scene.mediaType === EDraggableOptions.VIDEO ||
                    (scene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO && (
                        <Grid item xs={3} justifyContent={"center"}>
                            Move to Scene Delay
                            <Tooltip
                                title={"Can not be used while autoloop is enabled"}
                                disableHoverListener={!videoAutoLoop}
                            >
                                <TextField
                                    type="number"
                                    value={videoMoveToSceneDelay}
                                    disabled={videoAutoLoop}
                                    onChange={(e) => {
                                        if (parseInt(e.target.value) > -1) {
                                            setVideoMoveToSceneDelay(parseInt(e.target.value));
                                        }
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    ))}
            </Grid>
        </VTDialog>
    );
}
